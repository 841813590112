.group {
  &__role-div {
    margin-bottom: 40px;
  }

  &__role-title {
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #388e3c;
    margin-bottom: 30px;
  }

  &__checkbox-div {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #181f27;
  }
}
