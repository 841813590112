.error {
  &__main-body {
    background-color: #f8f8f8;
    height: 100%;
    width: 100%;
  }

  &__error-icon {
    margin: auto;
    padding-bottom: 59.7px;
  }

  &__error-title {
    font-family: Lato;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #041333;
    margin-bottom: 15px;
  }

  &__error-code {
    font-family: Lato;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: center;
    color: #e20000;
    margin-bottom: 15px;
  }

  &__resolution {
    font-family: Lato;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: center;
    color: #041333;
    margin-bottom: 30px;
  }

  &__button {
    width: auto;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    height: 56px;
    border-radius: 28px;
    background-color: #008bff;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 3rem;
  }
}
