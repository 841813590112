.table {
  &__main-body {
    margin: auto;
    width: 750px;
    height: auto;
    border-radius: 4px;
    background-color: #f8f8f8;
    padding: 24px;
    padding-top: 54px;
  }

  &__action-icon {
    float: right;
    font-size: 2rem;
    cursor: pointer;
    margin-top: -2.5rem;
    margin-right: -1rem;
  }
}
