.dialog {
  &__main-body {
    margin: 1rem;
  }

  &__table-div {
    padding-top: 4rem;
  }


}


.show {
  display: block;
}

.hide {
  display: none;
}
