body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login__error-text {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important;
  margin-top: 0.5rem; }

.login__hide {
  display: none; }

.login__text-area-error {
  background: #fff6f6 !important;
  box-shadow: none !important;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.show {
  display: block; }

.hide {
  display: none; }

.topbar__main-body {
  width: 100%;
  height: 49px;
  background-color: #ffffff;
  border-color: #e7e7e7; }

.topbar__logo-div {
  background: linear-gradient(to left, #243f95, #00b4a9);
  height: 100%;
  width: 20%;
  font-family: Lato;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center; }

.edit-icon {
  text-align: right !important;
  cursor: pointer; }

.pagination__main-body {
  height: 34px;
  width: 402.84px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #777; }

.pagination__previous-tile {
  height: 100%;
  width: 79.94px;
  border-right: 1px solid #ddd;
  color: #777777;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  display: flex;
  justify-content: center;
  align-items: center; }

.pagination__page-number-tiles {
  height: 100%;
  width: 33.8px;
  border-right: 1px solid #ddd;
  color: #337AB7;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  display: flex;
  justify-content: center;
  align-items: center; }

.pagination__ellipsis-tiles {
  height: 100%;
  width: 40px;
  border-right: 1px solid #ddd;
  color: #777777;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  display: flex;
  justify-content: center;
  align-items: center; }

.pagination__double-pagenumber-tiles {
  height: 100%;
  width: 41.58px;
  border-right: 1px solid #ddd;
  color: #337AB7;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  display: flex;
  justify-content: center;
  align-items: center; }

.pagination__next-tile {
  height: 100%;
  width: auto;
  color: #337AB7;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  display: flex;
  justify-content: center;
  align-items: center; }

.level__table-div {
  margin-top: 5rem; }

.dialog__main-body {
  margin: 1rem; }

.dialog__table-div {
  padding-top: 4rem; }

.show {
  display: block; }

.hide {
  display: none; }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}

.level__table-div {
  margin-top: 5rem; }

.personal__submision-error {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important; }

.personal__text-area-error {
  background: #fff6f6;
  box-shadow: none;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.personal__hide {
  display: none; }

.level__table-div {
  margin-top: 5rem; }

.personal__submision-error {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important; }

.personal__text-area-error {
  background: #fff6f6;
  box-shadow: none;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.personal__hide {
  display: none; }

.level__table-div {
  margin-top: 5rem; }

.personal__submision-error {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important; }

.personal__text-area-error {
  background: #fff6f6;
  box-shadow: none;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.personal__hide {
  display: none; }


.registration__main-body {
  padding: 40px; }

.personal__submision-error {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important; }

.personal__text-area-error {
  background: #fff6f6;
  box-shadow: none;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.personal__hide {
  display: none; }

.personal__submision-error {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important; }

.personal__text-area-error {
  background: #fff6f6;
  box-shadow: none;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.personal__hide {
  display: none; }

.level__table-div {
  margin-top: 5rem; }

.level__table-div {
  margin-top: 5rem; }

.level__table-div {
  margin-top: 5rem; }

.level__table-div {
  margin-top: 5rem; }

.table__main-body {
  margin: auto;
  width: 750px;
  height: auto;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 24px;
  padding-top: 54px; }

.table__action-icon {
  float: right;
  font-size: 2rem;
  cursor: pointer;
  margin-top: -2.5rem;
  margin-right: -1rem; }

.accordion__head-section {
  width: 702px;
  height: 84px;
  border-radius: 4px;
  background-color: #fcfcfc;
  padding-top: 22px;
  padding-left: 19px;
  padding-right: 24px;
  cursor: pointer; }

.accordion__header-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #3d3d3d;
  margin-bottom: 6px; }

.accordion__header-description {
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9c9c9c; }

.accordion__text-div {
  float: left; }

.accordion__icon-div {
  float: right;
  font-size: 26px !important;
  display: flex;
  justify-content: center;
  align-items: center; }

.accordion__plus-tick {
  width: 24px;
  height: 24px; }

.accordion__content-container {
  padding-bottom: 19px; }

.accordion__bottom-header-stroke {
  height: 1px;
  border: solid 0.8px #cfcfcf; }

.privilege__main-body {
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #fcfcfc;
  padding-top: 30px;
  padding-left: 20px; }

.checkbox__label {
  margin-left: 0.5rem; }

.group__role-div {
  margin-bottom: 40px; }

.group__role-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #388e3c;
  margin-bottom: 30px; }

.group__checkbox-div {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #181f27; }

.structure-modal__accordions_wrapper {
  margin: auto;
  width: 750px;
  max-height: 600px;
  overflow: auto;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 24px;
  padding-top: 54px;
  margin-top: 24px; }

.panel__main-body {
  width: 100%;
  height: 135px;
  background-color: #f8f8f8;
  padding-left: 49px;
  position: relative; }

.panel__text-div {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.panel__title {
  font-family: Lato;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #000000; }

.panel__right-column {
  height: 100% !important; }

.panel__left-column {
  height: 100% !important;
  position: relative; }

.panel__inner-left-column {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.panel__profile-div {
  position: relative;
  height: 100%;
  width: 100%; }

.panel__profile-circle {
  border-radius: 50%;
  width: 52px;
  height: 52px;
  background-color: #008bff;
  display: flex;
  justify-content: center;
  align-items: center; }

.panel__profile-circle-text {
  font-family: Lato;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff; }

.panel__inner-profile-div {
  right: 0;
  padding-right: 2rem;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.panel__contact-div {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.panel__name-text {
  text-align: right; }

.panel__email-text {
  text-align: right; }

.statement__main-body {
  margin: 0.5rem;
  border: solid 1px #041333;
  height: 98%;
  width: 98%;
  text-align: center;
  border-radius: 6px;
  overflow: scroll; }

.statement__pic-div {
  border: solid 1px #041333;
  border-radius: 6px;
  margin: 0.5rem;
  width: 120px;
  height: 120px; }

.statement__school-name {
  text-align: center;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1.5em;
  font-weight: 900; }

.statement__top-section {
  width: 100%;
  height: auto; }

.statement__name-div {
  height: 100%;
  width: 100%;
  position: relative; }

.statement__statement-table {
  margin: 0.5rem; }

.statement__installments-div {
  margin-left: 0.5rem !important; }

.statement__motto-prompt {
  text-align: center;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1.5em;
  font-weight: 500; }

.statement__school-details-div {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.statement__motto {
  text-align: center;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1.5em;
  font-weight: 300; }

.personal__submision-error {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important; }

.personal__text-area-error {
  background: #fff6f6;
  box-shadow: none;
  color: #e24c4b !important;
  border: 1px solid #e24c4b !important; }

.personal__hide {
  display: none; }

.personal__error-text {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #e24c4b !important;
  margin-top: 0.5rem; }

.error__main-body {
  background-color: #f8f8f8;
  height: 100%;
  width: 100%; }

.error__error-icon {
  margin: auto;
  padding-bottom: 59.7px; }

.error__error-title {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #041333;
  margin-bottom: 15px; }

.error__error-code {
  font-family: Lato;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #e20000;
  margin-bottom: 15px; }

.error__resolution {
  font-family: Lato;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #041333;
  margin-bottom: 30px; }

.error__button {
  width: auto;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  height: 56px;
  border-radius: 28px;
  background-color: #008bff;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 3rem; }

.payment__personal-info-div {
  box-shadow: 0 0 10px 0 rgba(255, 130, 0, 0.35);
  background: linear-gradient(to left, #243f95, #00b4a9);
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center; }

.payment__details-inner-div {
  text-align: center; }

.payment__pic-circular-div {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background: #ffffff;
  margin: auto; }

.payment__student-name {
  margin-top: 10px;
  font-family: Lato;
  font-size: 26px;
  font-weight: 300;
  margin: auto;
  color: #ffffff; }

.payment__admission-no {
  margin-top: 10px;
  font-family: Lato;
  font-size: 26px;
  font-weight: 300;
  margin: auto;
  color: #ffffff; }

.payment__prompt-div {
  padding: 1rem;
  position: relative;
  height: 220px; }

.payment__prompt {
  font-family: Lato;
  font-size: 26px;
  font-weight: 300;
  margin: auto;
  color: #000000;
  text-align: center; }

.payment__button-div {
  width: 100%;
  position: absolute;
  bottom: 0; }

.staff__main-body {
  margin-left: 47px;
  margin-right: 47px; }

.staff__title-panel-div {
  margin-bottom: 24px; }

.search__icon {
  width: 52px;
  height: 56px;
  font-size: 56px;
  color: #d0d2d3;
  margin-bottom: 24px;
  text-align: center; }

.search__wrapper_div {
  max-width: 379px;
  text-align: center; }

.search__title {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 1rem; }

.search__information {
  width: 379px;
  height: 89px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-align: center; }

.balance__main-div {
  display: flex;
  justify-content: center;
  align-items: center; }

.deadline__field-set-div {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.confirm__main-div {
  width: 100%;
  height: 100%; }

.confirm__title-div {
  box-shadow: 0 0 10px 0 rgba(255, 130, 0, 0.35);
  background: linear-gradient(to left, #243f95, #00b4a9);
  width: 100%;
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 26px;
  font-weight: 300;
  font-weight: bold;
  margin: auto;
  color: #ffffff; }

.confirm__prompt-text {
  font-family: Lato;
  font-size: 20px;
  font-weight: 300;
  margin: auto;
  color: #000000;
  text-align: center; }

.residence-page__modal-body {
  margin-top: 2rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #041333; }

.residence-page__cancel-button {
  margin-left: 10px; }

.residence-page__submit-button {
  margin-right: 20px; }

.error-band__main-body {
  box-shadow: 0 0 10px 0 rgba(255, 130, 0, 0.35);
  background: linear-gradient(to left, #fdb040, #ee2124);
  width: 100%;
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center; }

.general-announcement__container {
  margin: auto; }

