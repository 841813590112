.statement {
  &__main-body {
    margin: 0.5rem;
    border: solid 1px #041333;
    height: 98%;
    width: 98%;
    text-align: center;
    border-radius: 6px;
    overflow: scroll;
  }

  &__pic-div {
    border: solid 1px #041333;
    border-radius: 6px;
    margin: 0.5rem;
    width: 120px;
    height: 120px;
  }

  &__school-name {
    text-align: center;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1.5em;
    font-weight: 900;
  }

  &__top-section {
    width: 100%;
    height: auto;
  }

  &__name-div {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__statement-table {
    margin: 0.5rem;
  }

  &__installments-div {
    margin-left: 0.5rem !important;
  }

  &__motto-prompt {
    text-align: center;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1.5em;
    font-weight: 500;
  }

  &__school-details-div {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &__motto {
    text-align: center;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1.5em;
    font-weight: 300;
  }

}
