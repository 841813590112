.confirm {
  &__main-div {
    width: 100%;
    height: 100%;
  }
  &__title-div {
    box-shadow: 0 0 10px 0 rgba(255, 130, 0, 0.35);
    background: linear-gradient(to left, #243f95, #00b4a9);
    width: 100%;
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Lato;
    font-size: 26px;
    font-weight: 300;
    font-weight: bold;
    margin: auto;
    color: #ffffff;
  }

  &__prompt-text {
    font-family: Lato;
    font-size: 20px;
    font-weight: 300;
    margin: auto;
    color: #000000;
    text-align: center;
  }
}
