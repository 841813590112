.privilege {
  &__main-body {
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #fcfcfc;
    padding-top: 30px;
    padding-left: 20px;
  }
}
