.topbar {
  &__main-body {
    width: 100%;
    height: 49px;
    background-color: #ffffff;
    border-color: #e7e7e7;
  }

  &__logo-div {
    background: linear-gradient(to left, #243f95, #00b4a9);
    height: 100%;
    width: 20%;
    font-family: Lato;
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
