.staff {
  &__main-body {
    margin-left: 47px;
    margin-right: 47px;
  }

  &__title-panel-div {
    margin-bottom: 24px;
  }
}
