.login {
  &__error-text {
    font-family: Lato;
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #e24c4b !important;
    margin-top: 0.5rem;
  }

  &__hide {
    display: none;
  }

  &__text-area-error {
    background: #fff6f6 !important;
    box-shadow: none !important;
    color: #e24c4b !important;
    border: 1px solid #e24c4b !important;
  }
}
