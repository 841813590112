.pagination {
  &__main-body {
    height: 34px;
    width: 402.84px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #777;
  }

  &__previous-tile {
    height: 100%;
    width: 79.94px;
    border-right: 1px solid #ddd;
    color: #777777;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__page-number-tiles {
    height: 100%;
    width: 33.8px;
    border-right: 1px solid #ddd;
    color: #337AB7;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__ellipsis-tiles {
    height: 100%;
    width: 40px;
    border-right: 1px solid #ddd;
    color: #777777;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__double-pagenumber-tiles {
    height: 100%;
    width: 41.58px;
    border-right: 1px solid #ddd;
    color: #337AB7;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__next-tile {
    height: 100%;
    width: auto;
    color: #337AB7;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
