.residence-page {
  &__modal-body {
    margin-top: 2rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #041333;
  }

  &__cancel-button {
    margin-left: 10px;
  }

  &__submit-button {
    margin-right: 20px;
  }
}
