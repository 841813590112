.search {
  &__icon {
    width: 52px;
    height: 56px;
    font-size: 56px;
    color: #d0d2d3;
    margin-bottom: 24px;
    text-align: center;
  }

  &__wrapper_div {
    max-width: 379px;
    text-align: center;
  }

  &__title {
    font-family: Lato;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 1rem;
  }

  &__information {
    width: 379px;
    height: 89px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    text-align: center;
  }
}
